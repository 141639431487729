import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { GqlMutationResult } from "@/types";

export default function (assetUuid: string, properties: string[] = []): Promise<GqlMutationResult> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($assetUuid: ID!, $properties: [String!]) {
          refreshDeviceSettings(assetUuid: $assetUuid, properties: $properties) {
            statusCode
            message
            stamp
          }
        }
      `,
      variables: {
        assetUuid,
        properties: properties.length > 0 ? properties : null
      }
    })
    .then(response => {
      return response.data.refreshDeviceSettings;
    });
}
